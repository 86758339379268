
// <template lang="pug">
// include /mixins

// //- +b.main-categories-select
//   control-select(
//     name="main-categories"
//     styling="accent"
//     :size="['lg']"
//     :model-value="activeCategory",
//     @update:model-value="onChangeCategory($event)"
//     :track-by="'slug'",
//     :label-by="'title'",
//     :options="categories || []"
//     :searchable="false",
//     id="main-categories"
//   )
//     template(#singleLabel="{ option }")
//       +b.g-row--appearance_nowrap.--align_center.sk-space_pl--0.full-width
//         +b.g-cell.g-cols--narrow
//           img.img-contain(
//             :src="option.icon"
//             width="24"
//           )
//         +b.g-cell.g-cols--auto.ellipsis-content--1 
//           span {{ option.title }}
//     template(#option="{ option }")
//       +b.g-row--appearance_nowrap.--align_center.sk-space_pl--0.full-width
//         +b.g-cell.g-cols--narrow
//           +b.ds-inliner--size_lg
//             +e.body.IMG(
//                 :src="option.icon"
//               )
//         +b.g-cell.g-cols--10.ellipsis-content--1 {{ option.title }}

// slot(
//   :activeCategory="activeCategory"
//   :listRef="listRef.value"
//   :radioPrefixName="radioPrefixName"
//   :onChangeCategory="onChangeCategory"
//   :onKeypress="onKeypress"
// )

// </template>
import { defineComponent, onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainPageCategoryStore } from './store'
import { Category } from './types'

export default defineComponent({
  props: {
    initialCategory: {
      type: Object,
    },
    radioPrefixName: {
      type: String,
      default: 'rg-'
    },
    categories: {
      type: Array,
    },
  },
  setup(props: { initialCategory: Category; categories: Category[]; radioPrefixName: string }, { slots }) {
    const None = undefined

    const mainPageCategoryStore = useMainPageCategoryStore()
    const { category: activeCategory } = storeToRefs(mainPageCategoryStore)

    onMounted(() => {
      activeCategory.value = props.initialCategory
    })

    const listRef = ref(null)

    function onChangeCategory(category: Category) {
      if (JSON.stringify(activeCategory.value) !== JSON.stringify(category)) {
        activeCategory.value = category
      }
    }

    function setFocus(category: Category) {
      const node = listRef.value.querySelector(`[name=${props.radioPrefixName}${category.slug}]`)
      if (node) {
        node?.focus?.()
      }
    }

    function onKeypress(event: KeyboardEvent, category: Category, index: number) {
      let newIndex = index
      switch (event.key) {
        case '32': // space
        case 'Enter': // return
          onChangeCategory(category)
          break

        case 'ArrowUp': // up
        case 'ArrowLeft': // left
          newIndex = index - 1 >= 0 ? index - 1 : props.categories.length - 1
          onChangeCategory(props.categories[newIndex])
          setFocus(props.categories[newIndex])
          break

        case 'ArrowDown': // down
        case 'ArrowRight': // right
          newIndex = index + 1 > props.categories.length - 1 ? 0 : index + 1
          onChangeCategory(props.categories[newIndex])
          setFocus(props.categories[newIndex])
          break

        default:
          break
      }
      if ('Tab' !== event.key) {
        event.stopPropagation()
        event.preventDefault()
      }
    }

    return () => slots?.default?.({
      activeCategory: activeCategory.value,
      listRef,
      radioPrefixName: props.radioPrefixName,
      onChangeCategory,
      onKeypress,
    })
  },
})
